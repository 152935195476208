import React, { useState } from "react";


const TableBody = ({ tableData, columns }) => {
  return (
    <tbody>
      {tableData.map((data) => {
        return (
          <tr key={data.id}>
            {columns.map(({ accessor }) => {
              const tData = data[accessor] ? data[accessor] : "——";
              return <td key={accessor} dangerouslySetInnerHTML={{ __html: tData }}></td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
